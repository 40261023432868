<template>
  <div class="">
    <div class="container">
      <div v-if="isLoading" class="loader">Loading...</div>
      <div class="welcome-flow-container" v-else>
        <!-- {{ pageContent }} -->
        <div class="row align-items-center mobile-align content-align">
          <div class="col-12 col-lg-5 order-lg-3">
            <div class="mt-5">
              <div class="widget">
                <div class="widget-splash splash-line splash-position"></div>
                <div class="widget-body">
                  <h2 class="text-center"> {{pageContent && pageContent.formHeader ? pageContent.formHeader : 'Create professional profile'}}</h2>

                  <form @submit.prevent="registerUser">
                    <div class="form-group">
                      <label>{{pageContent && pageContent.formEmailLabel ? pageContent.formEmailLabel : 'Email'}}</label>
                      <input
                        type="email"
                        v-model="email"
                        class="form-control form-control-lg"
                        name="email"
                        autocomplete="email"
                        :placeholder="pageContent && pageContent.formEmailPlaceholder ? pageContent.formEmailPlaceholder : 'Your@company.dk'"
                        autofocus
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>{{pageContent && pageContent.formPasswordLabel ? pageContent.formPasswordLabel : 'Password'}}</label>
                      <input
                        type="password"
                        v-model="password"
                        class="form-control form-control-lg"
                        name="new-password"
                        autocomplete="new-password"
                        :placeholder="pageContent && pageContent.formPasswordPlaceholder ? pageContent.formPasswordPlaceholder : 'Minimum 6 characters'"
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>{{pageContent && pageContent.formConfirmPasswordLabel ? pageContent.formConfirmPasswordLabel : 'Confirm password'}}</label>
                      <input
                        type="password"
                        v-model="passwordConfirm"
                        :placeholder="pageContent && pageContent.formConfirmPasswordPlaceholder ? pageContent.formConfirmPasswordPlaceholder : 'Confirm your password'"
                        :class="
                          passwordConfirm.length > 0 &&
                          passwordConfirm !== password
                            ? 'form-control  form-control-lg is-invalid'
                            : 'form-control  form-control-lg'
                        "
                        required
                      />
                    </div>
                    <div
                      class="alert alert-info"
                      v-if="passwordMessage.length > 0"
                    >
                      {{ passwordMessage }}
                    </div>
                    <div class="alert alert-danger" v-if="errorMessage">
                      {{ errorMessage }}
                    </div>
                    <div class="form-group">
                      <button class="btn btn-primary btn-block btn-lg" :disabled="isUpdating">
                      <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      <span v-else>
                        {{pageContent && pageContent.formNextButton ? pageContent.formNextButton : 'Next'}} 
                      </span>
                      </button>
                    </div>

                    <div class="alert alert-success" v-if="successMessage">
                      {{ successMessage }}
                    </div>
                  </form>
                </div>
              </div>
              <div class="widget-footer-note">
                {{pageContent && pageContent.formExistingUserText ? pageContent.formExistingUserText : 'Already have a user? Sign in' }}
                <router-link to="/signin" class="color-first">{{pageContent && pageContent.formExistingUserSignInText ? pageContent.formExistingUserSignInText : 'here'}}</router-link>
                <br />
                {{pageContent && pageContent.formNotProfessionalText ? pageContent.formNotProfessionalText : 'Not a professional?' }}
                <router-link to="/student-signup" class="color-first"
                  >{{pageContent && pageContent.formCreateStudentProfileText ? pageContent.formCreateStudentProfileText : 'Create a student profile' }}</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-1 order-lg-2"></div>
          <div class="col-12 col-lg-6 order-lg-1 welcome-message">
            <div class="wm-content">
              <div class="header-position">
                <h1 class="heading-font">{{pageContent && pageContent.formHeader ? pageContent.formHeader : '' }}</h1>
                <ul class="usps p-0" v-if="signupData">
                  <li
                    v-for="(item, index) in signupData.signup_attributes"
                    :key="index"
                    class="list-font"
                  >
                    <span class="list-item-counter">{{ index + 1 }}</span
                    >{{ item.title }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUp',

  data: function () {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      passwordMessage: '',
      role: 'Professional',
      failureMessage: '',
      successMessage: '',
      loading: false,
    };
  },
  mounted: function () {
    var vm = this;
    vm.loading = true;
    vm.$store.dispatch("setSignupData", 'Professional').then(() => (vm.loading = false));
  },
  metaInfo: {
    title: 'Company profile - Sign up on Excelerate | Excelerate.dk',
    meta: [
      {
        name: 'description',
        content:
          'Excelerate is a digital platform that helps companies find students for project and thesis collaborations. Go to Excelerate professionals sign up page.',
      },
      {
        property: 'og:title',
        content: 'Company profile - Sign up on Excelerate | Excelerate.dk',
      },
      {
        property: 'og:description',
        content:
          'Excelerate is a digital platform that helps companies find students for project and thesis collaborations. Go to Excelerate professionals sign up page.',
      },
    ],
  },
  computed: {
    isLoading(){
      return this.$store.getters.generalIsLoading
    },
    isUpdating() {
      return this.$store.getters.authIsUpdating
    },
    signupData: function () {
      var vm = this;
      return vm.$store.getters.signupData;
    },
    errorMessage() {
      return this.$store.getters.authErrorMessage;
    },
    pageContent() {
      return this.$store.getters.professionalSignupContent
    }
    // signupImage() {
    //   return {
    //     backgroundImage: `url(${process.env.VUE_APP_API_BASE_URL}${this.signupData.image_path})`,
    //   };
    // },
  },
  methods: {
    registerUser() {
      var vm = this;
      const user = {
        email: this.email,
        password: this.password,
        payload: this.role,
      };
      if (vm.password === vm.passwordConfirm) {
        vm.$store.dispatch('registerUser', user); //registerUser
      } else {
        vm.passwordMessage = 'Please check your password confirmation';
      }
    },
  },
  created() {
    this.$store.dispatch('clearAuthState')

    let queryEmail = this.$route.query.email;
    if (typeof queryEmail != 'undefined' && queryEmail != '') {
      this.email = queryEmail;
    }
  },
};
</script>
<style scoped>
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(219, 95, 138, 0.2);
  border-right: 1.1em solid rgba(219, 95, 138, 0.2);
  border-bottom: 1.1em solid rgba(219, 95, 138, 0.2);
  border-left: 1.1em solid #db5f8a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.splash-position {
  margin-top: -1px;
  margin-left: -2px;
  margin-right: -1px;
}
.widget {
  box-shadow: none !important;
}
.usps li {
  margin-bottom: 20px;
}
.heading-font {
  font-size: 60px !important;
  color: white !important;
}
@media (max-width: 767px) {
  .heading-font {
    font-size: 36px !important;
  }
}
.list-font {
  font-size: 18px !important;
  color: white !important;
}
.list-item-counter {
  margin-right: 1rem;
  background-color: #bc1e73;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 32px;
}
.content-align {
  height: 100vh;
}
#user-pages .welcome-flow-container .welcome-message {
  margin-top: 0px !important;
}
@media screen and (max-width: 767px) {
  .mobile-align {
    flex-direction: column-reverse;
  }
  .content-align {
    height: auto;
  }
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
